import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Header from "../components/header"
import Contact from "../components/contact"
import Footer from "../components/footer"

const Products = () => {
  const data = useStaticQuery(graphql`
    query productList {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              mainImageUrl {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <Seo title={"Products"} />
      <Navbar />
      <Header />
      <section className="products section bd-container" id="products">
        <h1 className="product__title">Products</h1>

        <div className="product__container bd-grid">
          {data.allMarkdownRemark.edges.map(({ node }, i) => (
            <Link to={node.frontmatter.path} key={i}>
              <div className="product__content">
                <Img
                  fluid={node.frontmatter.mainImageUrl.childImageSharp.fluid}
                  className="product__img"
                />
              </div>
            </Link>
          ))}
        </div>
      </section>
      <Contact />
      <Footer />
    </div>
  )
}

export default Products
